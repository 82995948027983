import Button from "../components/Button";
import { Card, AnimSection } from "../components/FadeAnim";

function App() {
  return (
    <>
      <div className="backlight">
        <AnimSection content={<>
          <section className="home">
            <div className="home-content">
              <h1 className="text-one home-title">Rocky, le bot Discord ultime !</h1>
              <p className="text-two">RockyBot est LE bot parfait pour ton serveur Discord : tout-en-un, facile
                d'utilisation, et français !</p>
              <Button content={"Inviter RockyBot !"} newPage="true" link="/invite"></Button>
              <div onClick={() => document.getElementById("scroll").scrollIntoView()} className="scroll-arrow">
                <img src="./arrow.svg" alt="Fléche" width="50px" />
              </div>
            </div>
          </section></>
        }>
        </AnimSection>
      </div>

      <section id="scroll">
        <div className="content">
          <AnimSection content={<>
            <h2 className="section-title">Fonctionnalités</h2>
            <Card
              title="Un bot pour les remplacer tous !"
              description="Pourquoi avoir plus d'une dizaine de bots sur un serveur, quand on peut tous les remplacer par un seul ? RockyBot est LE bot français idéal pour votre serveur Discord !"
              image="./_XpX.webp" alt="Exemple du système d'expérience"></Card>
            <Card
              title="Une petite partie ?" pair="true"
              description="Des heures de fun à portée de main ! Avec Rocky, joue à des classiques comme le morpion, démineur et puissance 4 directement sur ton serveur Discord. Retrouve la liste de tous les jeux disponibles sur la page des commandes !"
              image="./_MorpionX.webp" alt="Exemple d'une partie de morpion"></Card>
            <Card
              title="Des outils pratiques pour tous !"
              description="RockyBot propose plein de fonctionnalités telles que la gestion de tickets, des suggestions, des embeds 100% personnalisables, et même des statistiques avancées pour votre serveur ! Un bot complet pour simplifier la vie des admins et des membres."
              image="./_EmbedX.webp" alt="Exemple d'utilisation de l'embedbuilder"></Card>
          </>}>
          </AnimSection>
        </div>
      </section>

      <section className="contact background-pair">
        <AnimSection content={<>
          <h2 className="section-title">Contactez Rocky !</h2>
          <div className="contact-text">
            <div className="section-subtitle">Vous avez trouvé des problèmes ou des idées ?</div>
            <p>Vous avez des suggestions, des idées d'amélioration, vous voulez signaler des bugs, ou tout
              simplement être notifié des dernières nouveautés de RockyBot ? Rejoignez le serveur de support !</p>

            <div className="buttons">
              <Button content={"Inviter RockyBot !"} newPage="true" link="/invite"></Button>
              <Button content={"Besoin d'aide ?"} newPage="true" link="/support" secondary="true"></Button>
            </div>
          </div>
        </>}>
        </AnimSection>
      </section>
    </>
  );
}

export default App;