import React from "react";
import NewLink from "./NewLink";
import "../footer.css";

export default function Footer() {
    return (
        <footer>
            <section>
                <div className="footer-content">
                    <div className="top">
                        <NewLink to="./" content={<img src="./RockyTitle.png" alt="Logo de RockyBot" />} noRelink="true" />
                    </div>
                    <div className="link-boxes">
                        <ul className="box">
                            <li className="link_name">Pages</li>
                            <li><NewLink content="Accueil" to="/" /></li>
                            <li><NewLink content="Commandes" to="/commandes" /></li>
                        </ul>
                        <ul className="box">
                            <li className="link_name">Autres liens</li>
                            <li><NewLink content="Conditions générales" to="/termes" /></li>
                            <li><NewLink content="Statut du bot" newPage="true" to="https://status.rockybot.pro/" /></li>
                        </ul>
                        <ul className="box">
                            <li className="link_name">Discord</li>
                            <li><NewLink content="Ajouter RockyBot" newPage="true" to="/invite" /></li>
                            <li><NewLink content="Rejoindre le support" newPage="true" to="/support" /></li>
                        </ul>
                    </div>
                </div>
            </section>
            <div className={"bottom-details"}>
                <div className="bottom_text">
                    <span className="copyright_text">Copyright © {new Date().getFullYear()} RockyBot   •   Tous droits réservés   •   Non affilié avec Discord Inc.</span>
                    <span className="policy_terms">
                        Créé par PRODEFIX👑
                    </span>
                </div>
            </div>
        </footer>
    );
};;